$(document).ready(function() {

  // date conversion function for form inputs
  function convertToDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  }

    // date conversion function for User instances' created-date attribute
  function convertToDateBar(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);
  }

  $('#filters #filter-btn').on("click",(function(event) {
    event.preventDefault(); // prevent the form from submitting

    var filters = {};

    // get the values of each filter input and store in filters object
    $('#filters [data-column]').each(function() {
      var column = $(this).data('column');
      var value = $(this).val();
      filters[column] = value;
    });

    // filter the table rows based on the filters object
    $('table tbody tr').each(function() {
      var show = true;
      var row = $(this);

      $.each(filters, function(column, value) {
        if (value && (column === "viewed-activities" || column === "released-activities" || column === "blocked-activities")) {
          if (parseFloat(row.find('td[data-column="' + column + '"]').text()) < parseFloat(value)) {
            show = false;
          }

        } else if (value && column == "working-company") {
          var inputValue = value.toLowerCase();
          var tdValue = row.find('td[data-column="' + column + '"]').text().toLowerCase();
          if (!tdValue.includes(inputValue)) {
            show = false;
          }

        } else if (value && column == "score") {
          var inputValue = parseFloat(value);
          var tdValue = parseFloat(row.find('td[data-column="' + column + '"]').text());
          if (isNaN(tdValue) || tdValue < inputValue) {
            show = false;
          }

        } else if (value && column === 'created') {
          var startDate = convertToDate($('#search_start_created_at').val());
          var endDate = convertToDate($('#search_end_created_at').val());
          var createdDate = convertToDateBar(row.find('td[data-column="' + column + '"]').text());

          if (createdDate < startDate || createdDate > endDate) {
            show = false;
          }


        } else if (value && row.find('td[data-column="' + column + '"]').text().toLowerCase().indexOf(value.toLowerCase()) === -1) {
          show = false;
        }
      });

      if (show) {
        row.show();
      } else {
        row.hide();
      }
    });
  }));
});
